const footerData = {
    contact: {
        company: "ZEUS IT SERVIÇOS E CONSULTORIA DE TI",
        address: "Caratinga - MG",
        document: 'CNPJ: 43.745.845/0001-19',
    },
    copyright:
        "Zeus IT. <a href='https://example.com/' target='_blank' rel='noopener noreferrer'>Todos os direitos reservados.</a>",
    socials: [
        {
            id: 1,
            icon: "fab fa-facebook-f",
            link: "https://www.facebook.com",
            title: "Facebook",
        },
        {
            id: 3,
            icon: "fab fa-instagram",
            link: "https://www.instagram.com/zeusit_oficial",
            title: "Instagram",
        },
        {
            id: 4,
            icon: "fab fa-linkedin",
            link: "https://www.linkedin.com/company/zeus-it",
            title: "Linkedin",
        },
    ],
};

export default footerData;

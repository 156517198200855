import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import ServiceBox from "@components/box-icon/layout-01";
import { SectionTitleType, ItemType } from "@utils/types";
import { SectionWrap } from "./style";

const ModalService = React.lazy(() =>
    import("../../../components/ui/modal-service")
);

const ServiceArea = ({ data }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalService, setModalService] = useState(Object);

    const openModal = useCallback((service) => {
        setModalOpen(true);
        setModalService(service);
    }, []);

    const showOrder = (number) => {
        switch (number) {
            case 0:
            case 3:
                return 'servicos-left';
            case 1:
            case 4:
                return 'servicos-middle';
            default:
                return 'servicos-right';
        }
    };

    return (
        <SectionWrap id="servicos">
            <Container>
                {data?.section_title && (
                    <SectionTitle
                        mb={["20px", null, "30px"]}
                        title={data.section_title?.title}
                        subtitle={data.section_title?.subtitle}
                    />
                )}
                <Row className="service-card-row">
                    {data?.items?.map((service, index) => (
                        <Col xl={4} lg={4} md={6} key={service.slug} mt="30px" className="service-card-div">
                            <ServiceBox
                                id={service.id}
                                icon={service.icon}
                                title={service.title}
                                subtitle={service?.subtitle}
                                desc={service.excerpt}
                                className={"service-card " + showOrder(index)}
                                path={"service-card-" + service.id}
                                modalOpen={openModal}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
            <ModalService
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                service={modalService}
            />
        </SectionWrap>
    );
};

ServiceArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default ServiceArea;

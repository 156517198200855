import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import BoxIcon from "@components/box-icon/layout-04";
import Anchor from "@ui/anchor";
import WorkFrom from "@components/forms/work-form";
import { HeadingType, TextType } from "@utils/types";
import { ContactFormBox, ContactInfoBox, ContentImg, SectionWrap } from "./style";
import image from "@assets/images/bg/work/work_transparent.png";

const ContactArea = ({ data }) => {
    return (
        <SectionWrap id="trabalhe-conosco">
            <Container id="trabalhe-conosco-container">
                {data?.section_title && (
                    <SectionTitle
                        mb={["20px", null, "30px"]}
                        subtitle={data.section_title?.subtitle}
                    />
                )}
                <Row alignItems="center">
                    <Col lg={6} id="trabalhe-conosco-left">
                        <ContentImg>
                            <img src={image} />
                        </ContentImg>
                    </Col>

                    <Col lg={6} id="trabalhe-conosco-right">
                        <ContactInfoBox>
                            {data?.headings?.[0] && (
                                <Heading
                                    as={data.headings[0]?.level}
                                    color="#fff"
                                >
                                    {data.headings[0]?.content}
                                </Heading>
                            )}

                            <br />

                            {data?.texts?.[0] && (
                                <Text fontSize="18px" color="#fff">
                                    {data.texts[0].content}
                                </Text>
                            )}

                            {data?.contact && (
                                <>
                                    {data.contact?.phone && (
                                        <Anchor
                                            display="block"
                                            path={`tel:${data.contact.phone}`}
                                        >
                                            <BoxIcon
                                                alignItems="center"
                                                mt="55px"
                                                layout={2}
                                                icon="fas fa-phone"
                                                heading="Call for advice now!"
                                                title={data.contact.phone}
                                            />
                                        </Anchor>
                                    )}
                                    {data.contact?.email && (
                                        <Anchor
                                            display="block"
                                            path={`mailto:${data.contact.email}`}
                                        >
                                            <BoxIcon
                                                alignItems="center"
                                                mt="55px"
                                                layout={2}
                                                icon="fas fa-envelope"
                                                heading="Say hello"
                                                title={data.contact.email}
                                            />
                                        </Anchor>
                                    )}
                                </>
                            )}
                        </ContactInfoBox>

                        <ContactFormBox>
                            <WorkFrom />
                        </ContactFormBox>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    );
};

ContactArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        contact: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string
        })
    })
};

export default ContactArea;

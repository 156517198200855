import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import Button from "@ui/button";
import { useMount } from "@hooks";
import { ButtonType, HeadingType, ImageType, TextType } from "@utils/types";
import {
    HeroBtnGroup,
    HeroContent,
    HeroContentImg,
    HeroWrapper,
    StyledBG,
    StyledDesc,
    StyledSubtitle,
    StyledSubtitle2
} from "./style";
import { Particles } from "react-particles";
import particlesConfig from "./particlesConfig.json";
import { loadSlim } from "tsparticles-slim";
import imageHero from "@assets/images/bg/home/home_transparent.png";

const HeroArea = ({ data }) => {
    const particlesInit = useCallback(async (engine) => {
        await loadSlim(engine);
    }, []);

    const mounted = useMount();
    if (!mounted) return null;

    return (
        <Fragment>
            <HeroWrapper>
                <StyledBG>
                    {/*<video loop="true" autoPlay="autoplay" muted>*/}
                    {/*    <source src={imageTop} type="video/mp4"/>*/}
                    {/*</video>*/}

                    <Particles
                        id="tsparticles"
                        height="100"
                        init={particlesInit}
                        options={particlesConfig}
                    />
                </StyledBG>

                <Container id="hero-container">
                    <HeroContent>
                        {data?.headings?.[0] && (
                            <StyledSubtitle as={data.headings[0]?.level}>
                                {data.headings[0]?.content}
                            </StyledSubtitle>
                        )}
                        {data?.headings?.[1] && (
                            <StyledSubtitle2 as={data.headings[1]?.level}>
                                {data.headings[1]?.content}
                            </StyledSubtitle2>
                        )}
                        {data?.texts?.[0] && (
                            <StyledDesc>{data.texts[0]?.content}</StyledDesc>
                        )}

                        <HeroBtnGroup>
                            {data?.buttons?.map(
                                ({ id, path, content, ...rest }) => (
                                    <Button
                                        key={id}
                                        m="10px"
                                        path={path}
                                        fontSize="14px"
                                        {...rest}
                                    >
                                        {content}
                                    </Button>
                                )
                            )}
                        </HeroBtnGroup>
                    </HeroContent>

                    <HeroContentImg>
                        <img src={imageHero} />
                    </HeroContentImg>
                </Container>
            </HeroWrapper>
        </Fragment>
    );
};

HeroArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default HeroArea;

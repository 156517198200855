import styled from "@styled";
import { Input } from "@ui/form-elements";
import Button from "@ui/button";

export const StyledButtonSave = styled(Button)`
  overflow: hidden;
  background: transparent;
  border: none;
  width: 40%;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 2%;

  &:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
    0 0 25px #03e9f4,
    0 0 50px #03e9f4,
    0 0 100px #03e9f4;

    span:nth-child(1), span:nth-child(2), span:nth-child(3), span:nth-child(4) {
      animation: none;
    }
  }

  span {
    position: absolute;
    display: block;
  }

  span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent, #08888f);
    animation: btn-anim1 1s linear infinite;
  }

  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%, 100% {
      left: 100%;
    }
  }

  span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #08888f);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
  }

  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%, 100% {
      top: 100%;
    }
  }

  span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 3px;
    background: linear-gradient(270deg, transparent, #08888f);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
  }

  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%, 100% {
      right: 100%;
    }
  }

  span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #08888f);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
  }

  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%, 100% {
      bottom: 100%;
    }
  }
`;

export const FormBox = styled.form`
  textarea, input, select {
    background-color: var(--bs-form-background-color);
    color: var(--bs-form-placeholder-color);

    &::placeholder {
      color: var(--bs-form-placeholder-color) !important;      
    }
    
    &::-webkit-input-placeholder {
      color: var(--bs-form-placeholder-color) !important;
    }
    
    &:-ms-input-placeholder {
      color: var(--bs-form-placeholder-color) !important;
    }

    &:hover {
      background-color: var(--bs-form-background-color);
    }

    &:focus {
      background-color: var(--bs-form-background-color) !important;
      box-shadow: 0 0 0 0.1rem var(--bs-site-color);
    }

    border-style: solid !important;
    border-width: 0 0 0 0 !important;
    border-radius: 0;
  }
  
  .input-group-text, .custom-select {
    background-color: var(--bs-form-background-color);
    border: none;
    color: var(--bs-form-placeholder-color) !important;
  }
`;
import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import BudgetArea from "../containers/budget/layout-01";

const Budget = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <div id="body">
                <Seo title="Orçamento" />

                <Header
                    transparent
                    data={{
                        ...globalContent["header"],
                        ...globalContent["menu-budget"],
                        contact: data.site.siteMetadata.contact,
                    }}
                />

                <main className="site-wrapper-reveal">
                    <BudgetArea data={content["budget-section"]} />
                </main>

                <Footer data={{ ...data.site.siteMetadata }} />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query resoultionsPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "resolutions" }, pageType: { eq: "frontpage" }) {
            content {
                ...PageContent
            }
        }
        services: allItService(sort: { id: DESC }, limit: 8) {
            nodes {
                ...ItServiceOne
            }
        }
        featuredBlogs: allArticle(
            filter: { is_featured: { eq: true } }
            limit: 2
        ) {
            nodes {
                ...BlogOne
            }
        }
        recentBlogs: allArticle(
            filter: { is_featured: { eq: false } }
            limit: 5
        ) {
            nodes {
                ...BlogTwo
            }
        }
    }
`;

Budget.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({}),
            }),
        }),
        services: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Budget;

import React from "react";
import PropTypes from "prop-types";
import { ScrollTopButton } from "./style";

const ScrollToTop = ({ className, ...props }) => {
    const onClickHandler = () => {
        window.open('https://wa.me/5533999666578', '_blank');
    };

    return (
        <ScrollTopButton
            type="button"
            onClick={onClickHandler}
            id='btn-whatsapp'
            {...props}
        >
            <i className="icon up fab fa-whatsapp"></i>
            <i className="icon down fab fa-whatsapp"></i>
        </ScrollTopButton>
    );
};

ScrollToTop.propTypes = {
    className: PropTypes.string
};

export default ScrollToTop;

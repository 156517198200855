import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Col, Row } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input, Textarea, Select } from "@ui/form-elements";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import InputGroup from "react-bootstrap/InputGroup";
import { StyledButtonSave, FormBox } from "./style";

const BudgetForm = () => {
    const formUrl = useFormUrl();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [serverState, setServerState] = useState({
        submitting: false
    });
    const handleServerResponse = (ok, form) => {
        setServerState({
            submitting: false
        });
        if (ok) {
            form.reset();
        }
    };

    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });

        var formData = new FormData();
        formData.append("project_type", data["project_type"]);
        formData.append("problem_description", data["problem_description"]);
        formData.append("project_description", data["project_description"]);
        formData.append("name", data["name"]);
        formData.append("company", data["company"]);
        formData.append("email", data["email"]);
        formData.append("whatsapp", data["whatsapp"]);
        formData.append("tipo", "orcamento");

        axios.post(formUrl, formData)
            .then((r) => {
                NotificationManager.success("Obrigado por entrar em contato conosco.", "Recebemos sua solicitação");
                handleServerResponse(true, form);
            })
            .catch((r) => {
                NotificationManager.error(r.response.data.error, "Erro", 5000);
                handleServerResponse(false, form);
            });
    };
    return (
        <FormBox onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Select
                            id="project_type"
                            hover={2}
                            feedbackText={errors?.project_type?.message}
                            state={
                                hasKey(errors, "project_type")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "project_type")}
                            {...register("project_type", {
                                required: "Selecione o projeto"
                            })}
                        >
                            <option value="api" selected>Desenvolvimento de API</option>
                            <option value="web">Aplicações web</option>
                            <option value="mobile">Aplicações mobile</option>
                            <option value="consultoria">Consultoria de TI</option>
                        </Select>
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            id="problem_description"
                            placeholder="Descreva o seu problema *"
                            hover={2}
                            state={
                                hasKey(errors, "problem_description")
                                    ? "error"
                                    : "success"
                            }
                            feedbackText={errors?.problem_description?.message}
                            showState={!!hasKey(errors, "problem_description")}
                            {...register("problem_description", {
                                required: "Informe o seu problema",
                                maxLength: {
                                    value: 300,
                                    message: "Digite no máximo 300 caracteres"
                                },
                                minLength: {
                                    value: 10,
                                    message: "Digite ao menos 10 caracteres"
                                }
                            })}
                        ></Textarea>
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            id="project_description"
                            placeholder="Descreva o projeto pensado *"
                            hover={2}
                            feedbackText={errors?.project_description?.message}
                            state={hasKey(errors, "project_description") ? "error" : "success"}
                            showState={!!hasKey(errors, "project_description")}
                            {...register("project_description", {
                                required: "Informe seu projeto"
                            })}
                        ></Textarea>
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="name"
                            placeholder="Nome *"
                            feedbackText={errors?.name?.message}
                            state={hasKey(errors, "name") ? "error" : "success"}
                            showState={!!hasKey(errors, "name")}
                            {...register("name", {
                                required: "Informe seu nome"
                            })}
                        />
                    </FormGroup>
                </Col>

                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="company"
                            placeholder="Empresa *"
                            feedbackText={errors?.company?.message}
                            state={
                                hasKey(errors, "company") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "company")}
                            {...register("company", {
                                required: "Informe sua empresa"
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            id="email"
                            type="email"
                            placeholder="E-mail *"
                            hover={2}
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "Informe um e-mail",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "E-mail inválido"
                                }
                            })}
                        />
                    </FormGroup>
                </Col>

                <Col lg={6}>
                    <FormGroup mb="20px">
                        <div className="input-group">
                            <InputGroup.Text><i className="fab fa-whatsapp"></i></InputGroup.Text>
                            <Input
                                type="text"
                                id="whatsapp"
                                placeholder="Whatsapp *"
                                feedbackText={errors?.whatsapp?.message}
                                state={
                                    hasKey(errors, "whatsapp") ? "error" : "success"
                                }
                                showState={!!hasKey(errors, "whatsapp")}
                                {...register("whatsapp", {
                                    required: "Informe seu whatsapp"
                                })}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg={{ span: 6, offset: 3 }} textAlign="center">
                    <StyledButtonSave type="submit" disabled={serverState.submitting}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Enviar
                    </StyledButtonSave>
                </Col>
            </Row>
            <NotificationContainer />
        </FormBox>
    );
};

export default BudgetForm;

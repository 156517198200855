import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CloseButton from "../close-button";
import { Container, Col, Row } from "@ui/wrapper";
import { BoxIconImg, StyledDesc } from "./style";

const ModalService = ({ onClose, isOpen, service }) => {
    return (
        <Modal show={isOpen} onHide={onClose} size={'xl'} className="modal-service">
            <Modal.Body>
                <Row gutters={{ lg: 20 }}>
                    <Col lg={12}>
                        <StyledDesc>{service?.subtitle}</StyledDesc>
                    </Col>
                    <Col lg={12}>
                        {service?.desc?.map((value) => (
                            <StyledDesc>{value.content}</StyledDesc>
                        ))}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

ModalService.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    service: PropTypes.object,
};

export default ModalService;

import styled from "@styled";

const StyledDiv = styled.div`
  padding: 10px;
  text-align: center;
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  display: none;

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

export default StyledDiv;
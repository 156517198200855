import styled, { space, themeGet } from "@styled";
import { boxImageAnimationSignal } from "../../../assets/css/animations";
import { Link } from "gatsby";

export const BoxIconInner = styled.div`
    padding-block-start: 36px;
    padding-block-end: 26px;
    padding-inline: 10px;
    border-radius: ${themeGet("radii.md")};
    background: transparent;
    border: 2px solid #1b2547;
    text-align: center;
    ${space};
    img {
        width: 80%;
    }
    min-height: 405px;
`;

export const BoxIconImg = styled.div`
    height: 90px;
    text-align: center;
    width: 90px;
    margin-inline: auto;
    margin-block-end: 29px;
    color: ${themeGet("colors.primary")};
    svg * {
        stroke: ${themeGet("colors.primary")};
        //stroke: ${themeGet("colors.black")};
    }
    i {
        color: ${themeGet("colors.primary")};
    }
`;

export const BoxIconContent = styled.div`
    text-align: center;
`;

export const BoxIcontitle = styled.h5`
    font-weight: 500;
    line-height: 1.25;
    margin-block-end: 12px;
`;

export const BoxIconText = styled.p`
    margin-block-end: 0;
    color: var(--bs-gray-400);
`;

export const BoxIconBtn = styled.div`
    margin-block-start: 20px;
`;

export const BoxCircleBtnWrap = styled.div`
    height: 56px;
    width: 56px;
    position: absolute;
    left: 50%;
    bottom: -28px;
    transform: translateX(-50%);
    .middle-dot {
        pointer-events: none;
        content: "";
        position: absolute;
        top: -12px;
        left: -12px;
        width: 80px;
        height: 80px;
        border-radius: ${themeGet("radii.round")};
        border: 2px solid ${themeGet("colors.primary")};
        opacity: 0;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        &.dot-2 {
            pointer-events: none;
            content: "";
            position: absolute;
            top: -12px;
            left: -12px;
            width: 80px;
            height: 80px;
            border-radius: ${themeGet("radii.round")};
            border: 2px solid ${themeGet("colors.primary")};
            opacity: 0;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
        }
    }
`;

export const BoxCircleBtnInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const BoxCircleBtn = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    border-radius: ${themeGet("radii.round")};
    font-size: 24px;
    color: #fff;
    background: var(--bs-site-color);
    .icon {
        color: #fff;
    }
    &:hover {
        color: #fff;
    }
`;

export const BoxIconWrap = styled.div`
    // &:hover {
    //     ${BoxIconInner} {
    //         border-color: #ffffff42;
    //         transform: translateY(-5px);
    //         box-shadow: ${themeGet("shadows.default")};
    //     }
    //
    //     ${BoxCircleBtnWrap} {
    //         .middle-dot {
    //             animation: ${boxImageAnimationSignal}
    //                 cubic-bezier(0, 0.55, 0.55, 1) 2s;
    //             animation-iteration-count: 1;
    //             animation-fill-mode: none;
    //             animation-fill-mode: forwards;
    //             animation-iteration-count: infinite;
    //         }
    //     }
    //     ${BoxCircleBtn} {
    //         background: ${themeGet("colors.primary")};
    //     }
    // }
`;

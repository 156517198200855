import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Button from "@ui/button";
import { ErrorWrap } from "./style";

const ErroArea = () => {
    return (
        <ErrorWrap>
            <Container>
                <Row>
                    <Col xl={7} lg={8} mx="auto">
                        <h1>404</h1>
                        <h2>Página não encontrada!</h2>
                        <p>
                            Desculpe, mas a página que tentou acessar não foi encontrada. Retorne à tela inicial para navegar pelo site.
                        </p>
                        <Button path="/">Voltar para tela inicial</Button>
                    </Col>
                </Row>
            </Container>
        </ErrorWrap>
    );
};

export default ErroArea;

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-accordion-jsx": () => import("./../../../src/pages/elements/accordion.jsx" /* webpackChunkName: "component---src-pages-elements-accordion-jsx" */),
  "component---src-pages-elements-box-icon-jsx": () => import("./../../../src/pages/elements/box-icon.jsx" /* webpackChunkName: "component---src-pages-elements-box-icon-jsx" */),
  "component---src-pages-elements-box-image-jsx": () => import("./../../../src/pages/elements/box-image.jsx" /* webpackChunkName: "component---src-pages-elements-box-image-jsx" */),
  "component---src-pages-elements-box-large-image-jsx": () => import("./../../../src/pages/elements/box-large-image.jsx" /* webpackChunkName: "component---src-pages-elements-box-large-image-jsx" */),
  "component---src-pages-elements-button-jsx": () => import("./../../../src/pages/elements/button.jsx" /* webpackChunkName: "component---src-pages-elements-button-jsx" */),
  "component---src-pages-elements-call-to-action-jsx": () => import("./../../../src/pages/elements/call-to-action.jsx" /* webpackChunkName: "component---src-pages-elements-call-to-action-jsx" */),
  "component---src-pages-elements-client-logo-jsx": () => import("./../../../src/pages/elements/client-logo.jsx" /* webpackChunkName: "component---src-pages-elements-client-logo-jsx" */),
  "component---src-pages-elements-counters-jsx": () => import("./../../../src/pages/elements/counters.jsx" /* webpackChunkName: "component---src-pages-elements-counters-jsx" */),
  "component---src-pages-elements-dividers-jsx": () => import("./../../../src/pages/elements/dividers.jsx" /* webpackChunkName: "component---src-pages-elements-dividers-jsx" */),
  "component---src-pages-elements-flexible-image-slider-jsx": () => import("./../../../src/pages/elements/flexible-image-slider.jsx" /* webpackChunkName: "component---src-pages-elements-flexible-image-slider-jsx" */),
  "component---src-pages-elements-google-map-jsx": () => import("./../../../src/pages/elements/google-map.jsx" /* webpackChunkName: "component---src-pages-elements-google-map-jsx" */),
  "component---src-pages-elements-gradation-jsx": () => import("./../../../src/pages/elements/gradation.jsx" /* webpackChunkName: "component---src-pages-elements-gradation-jsx" */),
  "component---src-pages-elements-lists-jsx": () => import("./../../../src/pages/elements/lists.jsx" /* webpackChunkName: "component---src-pages-elements-lists-jsx" */),
  "component---src-pages-elements-message-box-jsx": () => import("./../../../src/pages/elements/message-box.jsx" /* webpackChunkName: "component---src-pages-elements-message-box-jsx" */),
  "component---src-pages-elements-popup-video-jsx": () => import("./../../../src/pages/elements/popup-video.jsx" /* webpackChunkName: "component---src-pages-elements-popup-video-jsx" */),
  "component---src-pages-elements-pricing-box-jsx": () => import("./../../../src/pages/elements/pricing-box.jsx" /* webpackChunkName: "component---src-pages-elements-pricing-box-jsx" */),
  "component---src-pages-elements-progress-bar-jsx": () => import("./../../../src/pages/elements/progress-bar.jsx" /* webpackChunkName: "component---src-pages-elements-progress-bar-jsx" */),
  "component---src-pages-elements-progress-circle-jsx": () => import("./../../../src/pages/elements/progress-circle.jsx" /* webpackChunkName: "component---src-pages-elements-progress-circle-jsx" */),
  "component---src-pages-elements-social-networks-jsx": () => import("./../../../src/pages/elements/social-networks.jsx" /* webpackChunkName: "component---src-pages-elements-social-networks-jsx" */),
  "component---src-pages-elements-tabs-jsx": () => import("./../../../src/pages/elements/tabs.jsx" /* webpackChunkName: "component---src-pages-elements-tabs-jsx" */),
  "component---src-pages-elements-team-member-jsx": () => import("./../../../src/pages/elements/team-member.jsx" /* webpackChunkName: "component---src-pages-elements-team-member-jsx" */),
  "component---src-pages-elements-testimonials-jsx": () => import("./../../../src/pages/elements/testimonials.jsx" /* webpackChunkName: "component---src-pages-elements-testimonials-jsx" */),
  "component---src-pages-elements-timeline-jsx": () => import("./../../../src/pages/elements/timeline.jsx" /* webpackChunkName: "component---src-pages-elements-timeline-jsx" */),
  "component---src-pages-elements-typed-text-jsx": () => import("./../../../src/pages/elements/typed-text.jsx" /* webpackChunkName: "component---src-pages-elements-typed-text-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-orcamento-jsx": () => import("./../../../src/pages/orcamento.jsx" /* webpackChunkName: "component---src-pages-orcamento-jsx" */),
  "component---src-pages-style-jsx": () => import("./../../../src/pages/style.jsx" /* webpackChunkName: "component---src-pages-style-jsx" */),
  "component---src-templates-blog-category-index-jsx": () => import("./../../../src/templates/blog-category/index.jsx" /* webpackChunkName: "component---src-templates-blog-category-index-jsx" */),
  "component---src-templates-blog-list-index-jsx": () => import("./../../../src/templates/blog-list/index.jsx" /* webpackChunkName: "component---src-templates-blog-list-index-jsx" */),
  "component---src-templates-blog-tag-index-jsx": () => import("./../../../src/templates/blog-tag/index.jsx" /* webpackChunkName: "component---src-templates-blog-tag-index-jsx" */),
  "component---src-templates-single-blog-index-jsx": () => import("./../../../src/templates/single-blog/index.jsx" /* webpackChunkName: "component---src-templates-single-blog-index-jsx" */)
}


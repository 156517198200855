import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import BoxIcon from "@components/box-icon/layout-04";
import Anchor from "@ui/anchor";
import BudgetForm from "@components/forms/budget-form";
import { HeadingType, TextType } from "@utils/types";
import { BudgetFormBox, BudgetInfoBox, ServicesWrapper } from "./style";

const BudgetArea = ({ data }) => {
    return (
        <ServicesWrapper>
            <Container>
                <Row alignItems="center">
                    <Col lg={12}>
                        <BudgetInfoBox>
                            {data?.headings?.map((value, index) => (
                                <Heading
                                    as={value?.level}
                                    color="#fff"
                                    key={index}
                                    textAlign="center"
                                >
                                    {value?.content}
                                </Heading>
                            ))}

                            <br />

                            {data?.texts?.[0] && (
                                <Text fontSize="18px" color="#fff">
                                    {data.texts[0].content}
                                </Text>
                            )}

                            {data?.contact && (
                                <>
                                    {data.contact?.phone && (
                                        <Anchor
                                            display="block"
                                            path={`tel:${data.contact.phone}`}
                                        >
                                            <BoxIcon
                                                alignItems="center"
                                                mt="55px"
                                                layout={2}
                                                icon="fas fa-phone"
                                                heading="Call for advice now!"
                                                title={data.contact.phone}
                                            />
                                        </Anchor>
                                    )}
                                    {data.contact?.email && (
                                        <Anchor
                                            display="block"
                                            path={`mailto:${data.contact.email}`}
                                        >
                                            <BoxIcon
                                                alignItems="center"
                                                mt="55px"
                                                layout={2}
                                                icon="fas fa-envelope"
                                                heading="Say hello"
                                                title={data.contact.email}
                                            />
                                        </Anchor>
                                    )}
                                </>
                            )}
                        </BudgetInfoBox>

                        <BudgetFormBox>
                            <BudgetForm />
                        </BudgetFormBox>
                    </Col>
                </Row>
            </Container>
        </ServicesWrapper>
    );
};

BudgetArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        contact: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string
        })
    })
};

export default BudgetArea;

import styled from "@styled";
import Modal from "react-bootstrap/Modal";

export const StyledDesc = styled.p`
    margin-block-start: 15px;
    font-size: 18px;
    margin-inline-start: 34px;
    color: var(--bs-white);
`;

export const BoxIconImg = styled.div`
    height: 90px;
    text-align: center;
    width: 90px;
    margin-inline: auto;
    margin-block-end: 29px;
    color: var(--bs-white);
    i {
        color: var(--bs-white);
    }
    img {
        filter: brightness(0) invert(1);
    }
`;
import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    padding-block-start: 60px;
    padding-block-end: 52px;
    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 72px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 92px;
    }
    text-align: justify;
`;

export const ContentImg = styled.div`
    text-align: right;
    display: inline-table;
    transition: 1s ease;
    img:hover {
        -webkit-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        -ms-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transition: 1s ease;
    }
`;
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Row, Col } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input, Textarea } from "@ui/form-elements";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { StyledButtonSave, FormBox } from "../work-form/style";

const ContactForm = () => {
    const formUrl = useFormUrl();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [serverState, setServerState] = useState({
        submitting: false
    });
    const handleServerResponse = (ok, form) => {
        setServerState({
            submitting: false
        });
        if (ok) {
            form.reset();
        }
    };

    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });

        data['tipo'] = 'contato';

        axios({
            method: "post",
            url: formUrl,
            data: data
        })
            .then((r) => {
                NotificationManager.success('Obrigado por entrar em contato conosco.', 'Recebemos seu contato');
                handleServerResponse(true, form);
            })
            .catch((r) => {
                NotificationManager.error(r.response.data.error, 'Erro', 5000);
                handleServerResponse(false, form);
            });
    };
    return (
        <FormBox onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="name"
                            placeholder="Nome *"
                            feedbackText={errors?.name?.message}
                            state={hasKey(errors, "name") ? "error" : "success"}
                            showState={!!hasKey(errors, "name")}
                            {...register("name", {
                                required: "Informe seu nome"
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="email"
                            id="email"
                            placeholder="E-mail *"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "Informe seu e-mail",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "E-mail inválido"
                                }
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="subject"
                            placeholder="Assunto *"
                            feedbackText={errors?.subject?.message}
                            state={
                                hasKey(errors, "subject") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "subject")}
                            {...register("subject", {
                                required: "Informe um assunto"
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            id="message"
                            placeholder="Mensagem *"
                            feedbackText={errors?.message?.message}
                            state={
                                hasKey(errors, "message") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "message")}
                            {...register("message", {
                                required: "Mensagem obrigatória",
                                maxLength: {
                                    value: 150,
                                    message: "Informe no máximo 250 caracteres"
                                },
                                minLength: {
                                    value: 10,
                                    message: "Informe no mínimo 10 caracteres"
                                }
                            })}
                        ></Textarea>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12} textAlign="center">
                    <StyledButtonSave type="submit" disabled={serverState.submitting}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Enviar
                    </StyledButtonSave>
                </Col>
            </Row>
            <NotificationContainer/>
        </FormBox>
    );
};

export default ContactForm;

import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "@ui/wrapper";
import Text from "@ui/text";
import SectionTitle from "@ui/section-title";
import { ButtonType, HeadingType, SectionTitleType, TextType } from "@utils/types";
import { ContentImg, SectionWrap } from "./style";
import { StyledSubtitle } from "../../../components/ui/section-title/style";
import image from "@assets/images/bg/about/about_transparent.png";

const AboutArea = ({ data }) => {
    return (
        <SectionWrap id="sobre">
            <Container>
                {data?.section_title?.subtitle && (
                    <StyledSubtitle $layout={1}>
                        {data.section_title.subtitle}
                    </StyledSubtitle>
                )}
                {data?.section_title && (
                    <SectionTitle
                        mb={["47px", null, "60px"]}
                        title={data.section_title?.title}
                    />
                )}

                <Row>
                    <Col lg={{ span: 6 }} id="sobre-left">
                        {data?.texts?.map(
                            ({ content }) => (
                                <Text fontSize="18px" mb="20px">
                                {content}
                                </Text>
                            )
                        )}
                    </Col>
                    <Col lg={{ span: 6 }} id="sobre-right">
                        <ContentImg>
                            <img src={image} />
                        </ContentImg>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    );
};

AboutArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default AboutArea;

const transitionDelay = 1000;

exports.shouldUpdateScroll = ({
                                  routerProps: { location },
                                  getSavedScrollPosition
                              }) => {
    if (location.action === "PUSH" && (sessionStorage.getItem("currentHash") === null || sessionStorage.getItem("currentHash") === location.hash)) {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else if (
        [
            "#sobre",
            "#servicos",
            "#contato",
            "#blog",
            "#trabalhe-conosco"
        ].filter((value) => value == location.hash).length > 0
    ) {
        window.setTimeout(
            () => {
                let section = document.getElementById(location.hash.replace("#", ""));
                if (section) {
                    let posY = Math.abs(window.scrollY - section.getBoundingClientRect().top);
                    window.scrollTo(0, posY);
                }
            },
            transitionDelay
        );
    }

    sessionStorage.setItem("currentHash", location.hash);
    return false;
};

window.addEventListener("scroll", function() {
    // let pageBottom = window.scrollY + window.innerHeight
    // console.log(service.getBoundingClientRect().top, pageBottom + 10);

    let size = 550;

    let service = document.getElementById("servicos");
    if (service !== null &&
        service.getBoundingClientRect().top < size &&
        !document.querySelector(".service-card").classList.contains("active")
    ) {
        document.querySelectorAll(".service-card").forEach(function(card) {
            card.classList.add("active");
        });
    }

    let sections = ["contato", "sobre", "trabalhe-conosco", "blog"];

    sections.map((value) => {
        let section = document.getElementById(value);
        if (section !== null &&
            section.getBoundingClientRect().top < size &&
            !section.querySelector(".container").classList.contains("active")
        ) {
            section.querySelector(".container").classList.add("active");
        }
    });
});

let loadInterval = null;
let loadTimeout = true;

const showBody = () => {
    let body = document.getElementById("body");
    if (body) {
        body.style.display = "block";
        if (document.getElementById("div-logo-preload")) {
            document.getElementById("div-logo-preload").style.display = "none";
        }
        return true;
    }
    return false;
};

const showContent = async () => {
    showBody();

    let btn = document.getElementById("btn-whatsapp");
    if (btn) {
        btn.style.display = "block";
    }
    // document.body.style.backgroundColor = "#060922";
    document.body.style.backgroundColor = "#121212";
    document.documentElement.style.setProperty(
        "--bs-site-background-color",
        "#121212"
    );

    // sessionStorage.setItem("mainLoading", 0);
};

const showPreLoad = () => {
    sessionStorage.setItem("mainLoading", 1);

    let body = document.getElementById("body");
    if (body) {
        body.style.display = "none";
    }

    let btn = document.getElementById("btn-whatsapp");
    if (btn) {
        btn.style.display = "none";
    }

    // document.body.style.backgroundColor = "#4ec3e0";
    document.body.style.backgroundColor = "#11111e";
    loadTimeout = false;
};

const checkShowContent = () => {
    const showBodyInterval = setInterval(() => {
        showContent().then((result) => {
            if (result) {
                clearInterval(showBodyInterval);
            }
        });
    }, 500);
};

if (
    !(sessionStorage.getItem("mainLoading") * 1) &&
    (window.location.pathname === "" || window.location.pathname === "/")
) {
    window.addEventListener("load", () => {
        loadInterval = setInterval(() => {
            if (
                document !== null &&
                document.getElementById("div-logo-preload") !== null
            ) {
                document.getElementById("div-logo-preload").style.display =
                    "table-cell";
                if (loadTimeout) {
                    showPreLoad();

                    setTimeout(() => {
                        if (
                            document !== null &&
                            document.getElementById("div-logo-preload") !== null
                        ) {
                            document.getElementById(
                                "div-logo-preload"
                            ).style.animation = "fadeOut 2s";
                        }
                    }, 4000);

                    setTimeout(() => showContent().then((r) => clearInterval(loadInterval)), 5500);
                }
            }
        }, 500);
    });
} else {
    window.addEventListener("load", () => {
        checkShowContent();
    });
}

const router = require("@reach/router");
router.globalHistory.listen(() => {
    checkShowContent();
});

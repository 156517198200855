import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import Logo from "@components/logo";
import { MainMenu, MobileMenu } from "@components/menu";
import Flyout, { FlyoutBody, FlyoutHeader } from "@ui/flyout";
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from "@ui/off-canvas";
import SearchForm from "@components/forms/search-form/layout-03";
import CloseButton from "@ui/close-button";
import BurgerButton from "@ui/burger-button";
import { useSticky } from "@hooks";
import { HeaderBottom, HeaderCol, HeaderElement, HeaderMain, HeaderNavigation, HeaderWrap } from "./style";
import "@assets/css/site.css";

const Header = ({ data, transparent }) => {
    const { sticky, headerRef, fixedRef } = useSticky();
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [offCanvasOpen, setOffcanvasOpen] = useState(false);

    const flyoutHandler = () => {
        setFlyoutOpen((prevState) => !prevState);
    };
    const offCanvasHandler = () => {
        setOffcanvasOpen((prevState) => !prevState);
    };

    return (
        <Fragment>
            <HeaderWrap
                ref={headerRef}
                isSticky={sticky}
                $transparent={transparent}
            >
                <HeaderBottom ref={fixedRef} isSticky={sticky}>
                    <Container>
                        <HeaderMain>
                            <HeaderCol left>
                                <Logo
                                    py="14px"
                                    variant={
                                        transparent && !sticky
                                            ? "white"
                                            : "dark"
                                    }
                                />
                            </HeaderCol>
                            <HeaderCol center>
                                {data?.menu && (
                                    <HeaderNavigation>
                                        <MainMenu
                                            color={
                                                transparent && !sticky
                                                    ? "white"
                                                    : "dark"
                                            }
                                            alignment="right"
                                            menuData={data.menu}
                                            space={2}
                                            vSpace={2}
                                            bottomLine={false}
                                        />
                                    </HeaderNavigation>
                                )}
                                <HeaderElement
                                    pl="25px"
                                    display={["flex", null, null, null, "none"]}
                                >
                                    <BurgerButton
                                        className="burger-btn"
                                        onClick={offCanvasHandler}
                                    />
                                </HeaderElement>
                            </HeaderCol>
                        </HeaderMain>
                    </Container>
                </HeaderBottom>
            </HeaderWrap>
            <Flyout isOpen={flyoutOpen}>
                <FlyoutHeader px="15px" textAlign="right">
                    <CloseButton size="large" onClick={flyoutHandler} />
                </FlyoutHeader>
                <FlyoutBody>
                    <SearchForm />
                </FlyoutBody>
            </Flyout>
            <OffCanvas
                scrollable={true}
                isOpen={offCanvasOpen}
                onClick={offCanvasHandler}
            >
                <OffCanvasHeader onClick={offCanvasHandler}>
                    <Logo darkLogo align={{ default: "flex-start" }} />
                </OffCanvasHeader>
                <OffCanvasBody>
                    {data?.menu && <MobileMenu menuData={data.menu} />}
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    );
};

Header.propTypes = {
    data: PropTypes.shape({
        menu: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    transparent: PropTypes.bool,
};

Header.defaultProps = {
    transparent: false,
};

export default Header;

import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import HeroArea from "@containers/hero/layout-05";
import ITServiceArea from "@containers/it-service/layout-04";
import ContactArea from "@containers/contact/layout-05";
import Work from "@containers/work/layout-01";
import BlogArea from "@containers/blog/layout-01";
import AboutArea from "@containers/about/layout-04";
import LogGif from "@assets/images/animation_Contrast_p1da4t82.gif";
import StyledDiv from "./style";

const Index = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const recentBlogs = data.recentBlogs.nodes.sort(function(a, b) {
        return new Date(b.postedAt.date) - new Date(a.postedAt.date);
    });

    console.clear();
    console.log(recentBlogs);

    return (
        <Layout location={location}>
            <StyledDiv id="div-logo-preload">
                <img src={LogGif} className="spin"/>
            </StyledDiv>

            <div id="body">
                <Seo/>
                <Header
                    transparent
                    data={{
                        ...globalContent["header"],
                        ...globalContent["menu"],
                        contact: data.site.siteMetadata.contact,
                    }}
                />
                <main className="site-wrapper-reveal">
                    <HeroArea data={content["hero-section"]} />
                    <ITServiceArea
                        data={{
                            ...content["service-section"],
                            items: data.services.nodes,
                        }}
                    />
                    <ContactArea data={content["contact-section"]} />
                    <AboutArea data={content["about-section"]} />
                    <Work data={content["work-section"]} />
                    <BlogArea
                        data={{
                            ...content["blog-section"],
                            featuredBlogs: data.featuredBlogs.nodes,
                            recentBlogs: data.recentBlogs.nodes,
                        }}
                    />
                </main>
                <Footer data={{ ...data.site.siteMetadata }} />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query resoultionsPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "resolutions" }, pageType: { eq: "frontpage" }) {
            content {
                ...PageContent
            }
        }
        services: allItService(sort: { id: DESC }, limit: 8) {
            nodes {
                ...ItServiceOne
            }
        }
        featuredBlogs: allArticle(
            filter: { is_featured: { eq: true } }
            limit: 2
        ) {
            nodes {
                ...BlogOne
            }
        }
        recentBlogs: allArticle(
            filter: { is_featured: { eq: false } }
            limit: 15
        ) {
            nodes {
                ...BlogTwo
            }
        }
    }
`;

Index.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({}),
            }),
        }),
        services: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Index;

import styled, { themeGet, device } from "@styled";

export const SectionWrap = styled.section`
    position: relative;
    isolation: isolate;
    padding-block: 60px;
    background-color: var(--bs-site-background-color);
    ${device.medium} {
        padding-block: 80px;
    }
    ${device.large} {
        padding-block: 100px;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    inset: 0 auto 0 0;
    z-index: -1;
`;

export const ContactInfoBox = styled.div`
    margin-block-end: 45px;
    ${device.large} {
        margin-block-end: 0;
    }
    p {
        text-align: justify;
    }
`;

export const ContactFormBox = styled.div`
    border-radius: ${themeGet("radii.md")};
    text-align: center;
    padding-inline: 20px;
    padding-block-start: 32px;
    padding-block-end: 40px;

    ${device.medium} {
        padding-inline: 50px;
        padding-block-start: 42px;
        padding-block-end: 50px;
    }
  
    background-color: var(--bs-site-background-color);
    padding-right: 0 !important;
    padding-left: 0 !important;
`;

export const ContactFormTitle = styled.div`
    margin-block-end: 40px;
`;

export const ContentImg = styled.div`
    text-align: right;
    display: inline-table;
    transition: 1s ease;
    img:hover {
        -webkit-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        -ms-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transition: 1s ease;
    }
`;
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import ContactForm from "@components/forms/contact-form";
import { HeadingType, TextType } from "@utils/types";
import {
    StyledSection,
    LeftBox,
    StyledHeading,
    StyledDesc,
    ContentImg,
    ContactFormBox,
    ButtonBudget,
    StyledBudget
} from "./style";
import image from "@assets/images/bg/contact/contact_transparent.png";
import { height } from "styled-system";

const ContactArea = ({ data }) => {
    return (
        <StyledSection id="contato">
            <Container id="contato-container">
                {data?.section_title && (
                    <SectionTitle
                        mb={["20px", null, "30px"]}
                        title={data.section_title?.title}
                        subtitle={data.section_title?.subtitle}
                    />
                )}

                <Row alignItems="center">
                    <Col lg={6} textAlign="center" id="contato-left">
                        <StyledBudget>Faça o primeiro movimento em direção <br/>ao desenvolvimento do seu projeto.</StyledBudget>


                        <ButtonBudget
                            path="/orcamento"
                            fontWeight={500}
                        >
                            Faça um orçamento agora
                        </ButtonBudget>

                        <br/><br/>

                        <ContentImg>
                            <img src={image} />
                        </ContentImg>
                    </Col>

                    <Col lg={6} style={{ paddingLeft: 0, height: "100vh" }} id="contato-right">
                        <LeftBox>
                            {data?.headings?.[0] && (
                                <StyledHeading
                                    as={data.headings[0].level}
                                    dangerouslySetInnerHTML={{
                                        __html: data.headings[0].content
                                    }}
                                />
                            )}
                            {data?.texts?.[0] && (
                                <StyledDesc>{data.texts[0].content}</StyledDesc>
                            )}
                        </LeftBox>

                        <ContactFormBox>
                            <ContactForm />
                        </ContactFormBox>
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    );
};

ContactArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType))
    })
};

export default ContactArea;

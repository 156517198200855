import React from "react";
import PropTypes from "prop-types";
import ReactVivus from "react-vivus";
import { IDType, ImageIconType } from "@utils/types";
import {
    BoxCircleBtn,
    BoxCircleBtnInner,
    BoxCircleBtnWrap,
    BoxIconContent,
    BoxIconImg,
    BoxIconInner,
    BoxIconText,
    BoxIcontitle,
    BoxIconWrap,
} from "./style";

const BoxIcon = ({ id, icon, title, subtitle, desc, path, className, modalOpen, ...restProps }) => {
    return (
        <BoxIconWrap>
            <BoxIconInner className={className} {...restProps} id={id}>
                {icon?.svg?.src?.publicURL && (
                    <BoxIconImg>
                        <ReactVivus
                            id={`svg-${id}`}
                            option={{
                                file: icon.svg.src.publicURL,
                                type: "oneByOne",
                                animTimingFunction: "EASE",
                                duration: 250,
                            }}
                        />
                    </BoxIconImg>
                )}
                {icon?.png?.src?.publicURL && (
                    <BoxIconImg>
                        <img src={icon?.png?.src?.publicURL} />
                    </BoxIconImg>
                )}
                <BoxIconContent>
                    {title && <BoxIcontitle>{title}</BoxIcontitle>}
                    {subtitle && <BoxIconText>{subtitle}</BoxIconText>}
                    {path.length > 0 && (
                        <BoxCircleBtnWrap>
                            <BoxCircleBtnInner>
                                <div className="middle-dot"></div>
                                <div className="middle-dot dot-2"></div>
                                <BoxCircleBtn data-service-id={id} href="/orcamento">
                                    <i className="icon fas fa-book-open"></i>
                                </BoxCircleBtn>
                            </BoxCircleBtnInner>
                        </BoxCircleBtnWrap>
                    )}
                </BoxIconContent>
            </BoxIconInner>
        </BoxIconWrap>
    );
};

BoxIcon.propTypes = {
    id: IDType,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    desc: PropTypes.array,
    path: PropTypes.string,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(ImageIconType),
    ]),
    className: PropTypes.string,
    modalOpen: PropTypes.func,
};

BoxIcon.defaultProps = {
    path: "",
};

export default BoxIcon;

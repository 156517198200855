import styled, { themeGet, device, color } from "@styled";

export const ServicesWrapper = styled.section`
    padding-block-start: 80px;
    padding-block-end: 50px;
    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 70px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 90px;
    }
    ${color}
`;

export const BudgetInfoBox = styled.div`
    margin-block-end: 45px;
    ${device.large} {
        margin-block-end: 0;
    }
    p {
        text-align: justify;
    }
`;

export const BudgetFormBox = styled.div`
    border-radius: ${themeGet("radii.md")};
    text-align: center;
    padding-inline: 20px;
    padding-block-start: 32px;
    padding-block-end: 40px;

    ${device.medium} {
        padding-inline: 50px;
        padding-block-start: 42px;
        padding-block-end: 50px;
    }
  
    background-color: var(--bs-site-background-color);
    padding-right: 0 !important;
    padding-left: 0 !important;
`;
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Col, Row } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input } from "@ui/form-elements";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import InputGroup from "react-bootstrap/InputGroup";
import { StyledInputFile, StyledButtonSave, FormBox } from "./style";

const ContactForm = () => {
    const formUrl = useFormUrl();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [serverState, setServerState] = useState({
        submitting: false
    });
    const handleServerResponse = (ok, form) => {
        setServerState({
            submitting: false
        });
        if (ok) {
            form.reset();
        }
    };

    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });

        var formData = new FormData();
        formData.append("document", data.document[0]);
        formData.append("name", data["name"]);
        formData.append("email", data["email"]);
        formData.append("whatsapp", data["whatsapp"]);
        formData.append("linkedin", data["linkedin"]);
        formData.append("github", data["github"]);
        formData.append("tipo", "fale-conosco");

        axios.post(formUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((r) => {
                NotificationManager.success("Obrigado por entrar em contato conosco.", "Recebemos seu contato");
                handleServerResponse(true, form);
            })
            .catch((r) => {
                NotificationManager.error(r.response.data.error, "Erro", 5000);
                handleServerResponse(false, form);
            });
    };
    return (
        <FormBox onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="name"
                            placeholder="Nome *"
                            feedbackText={errors?.name?.message}
                            state={hasKey(errors, "name") ? "error" : "success"}
                            showState={!!hasKey(errors, "name")}
                            {...register("name", {
                                required: "Informe seu nome"
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="email"
                            id="email"
                            placeholder="E-mail *"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "Informe seu e-mail",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "E-mail inválido"
                                }
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <div className="input-group">
                            <InputGroup.Text><i className="fab fa-whatsapp"></i></InputGroup.Text>
                            <Input
                                type="text"
                                id="whatsapp"
                                placeholder="Whatsapp *"
                                feedbackText={errors?.whatsapp?.message}
                                state={
                                    hasKey(errors, "whatsapp") ? "error" : "success"
                                }
                                showState={!!hasKey(errors, "whatsapp")}
                                {...register("whatsapp", {
                                    required: "Informe seu whatsapp"
                                })}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <div className="input-group">
                            <InputGroup.Text><i className="fab fa-linkedin"></i></InputGroup.Text>
                            <Input
                                type="text"
                                id="linkedin"
                                placeholder="https://www.linkedin.com/in/joaosilva"
                                feedbackText={errors?.linkedin?.message}
                                state={
                                    hasKey(errors, "linkedin") ? "error" : "success"
                                }
                                showState={!!hasKey(errors, "linkedin")}
                                {...register("linkedin", {
                                    required: "Informe seu linkedin"
                                })}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>

            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <div className="input-group">
                            <InputGroup.Text><i className="fab fa-github"></i></InputGroup.Text>
                            <Input
                                type="text"
                                id="github"
                                placeholder="https://github.com/joaosilva"
                                feedbackText={errors?.github?.message}
                                state={
                                    hasKey(errors, "github") ? "error" : "success"
                                }
                                showState={!!hasKey(errors, "github")}
                                {...register("github", {
                                    required: "Informe seu github"
                                })}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <StyledInputFile
                            type="file"
                            id="document"
                            placeholder="Currículo"
                            feedbackText={errors?.document?.message}
                            state={
                                hasKey(errors, "document") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "document")}
                            {...register("document", {
                                required: "Anexe seu currículo"
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg={12} textAlign="center">
                    <StyledButtonSave type="submit" disabled={serverState.submitting}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Enviar
                    </StyledButtonSave>
                </Col>
            </Row>
            <NotificationContainer />
        </FormBox>
    );
};

export default ContactForm;

import styled, { device, themeGet } from "@styled";
import Button from "@ui/button";

export const StyledSection = styled.section`
    padding-block-start: 55px;
    padding-block-end: 60px;
    ${device.medium} {
        padding-block-start: 75px;
        padding-block-end: 80px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 100px;
    }
`;

export const LeftBox = styled.div`
    margin-block-end: 45px;
    ${device.large} {
        margin-block-end: 0;
    }
`;

export const StyledHeading = styled.h3`
    font-weight: 600;
    position: relative;
    line-height: 1.4;
    padding-inline-start: 34px;
    padding-inline-end: 30px;
    span {
        color: ${themeGet("colors.primary")};
    }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0px;
        width: 4px;
        height: 94%;
        transform: translateY(-50%);
        background-color: var(--bs-site-color);
    }
`;

export const StyledDesc = styled.p`
    margin-block-start: 15px;
    font-size: 18px;
    margin-inline-start: 34px;
    color: ${themeGet("colors.text")};
`;

export const ContactFormBox = styled.div`
    border-radius: ${themeGet("radii.md")};
    text-align: center;
    padding-inline: 20px;
    padding-block-start: 32px;
    padding-block-end: 40px;

    ${device.medium} {
        padding-inline: 50px;
        padding-block-start: 42px;
        padding-block-end: 50px;
    }
  
    background-color: var(--bs-site-background-color);

    padding-left: 36px !important;
    padding-right: 26px !important;
`;

export const ContentImg = styled.div`
    text-align: right;
    display: inline-table;
    transition: 1s ease;
    img:hover {
        -webkit-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        -ms-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transition: 1s ease;
    }
`;

export const ButtonBudget = styled(Button)`
    text-decoration: none;
`;

export const StyledBudget = styled.p`
    font-size: 18px;
    color: ${themeGet("colors.text")};
`;
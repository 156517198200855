import styled, { device } from "@styled";

export const HeroWrapper = styled.section`
    display: flex;
    align-items: center;
    position: relative;
    isolation: isolate;
    //background-color: #000;
    min-height: 100vh;

    @media only screen and (max-width: 1365px) {
        min-height: 100vh;
        //min-height: 50dvh;
        padding-top: 0;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    inset: 0;
    z-index: -1;
    & > div {
        height: 100%;
        width: 100%;
    }
    & > video {
        max-height: 100%;

        width: 100%;
        object-fit: fill;
        position: absolute;

        @media only screen and (max-width: 1365px) {
            min-height: 100vh;
            //min-height: 50dvh;
        }
    }
`;

export const HeroSeparator = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    line-height: 0;
    bottom: 0;
    svg {
        fill: #f8f8f8;
        height: 100px;
        width: 100%;
    }
    i {
        color: #f8f8f8;
        height: 100px;
        width: 100%;
    }
`;

export const HeroContent = styled.div`
    text-align: left;
    width: 90%;
    display: inline-table;

    @media (min-width: 992px) {
        width: 50%;
    }
`;

export const HeroContentImg = styled.div`
    text-align: right;
    width: 50%;
    display: inline-table;
    transition: 1s ease;
    img:hover {
        -webkit-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        -ms-transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg)
            scale3d(1, 1, 1);
        transition: 1s ease;
    }
`;

export const HeroBtnGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-block-start: 15px;
    ${device.small} {
        margin-block-start: 30px;
    }
    ${device.medium} {
        margin-block-start: 40px;
    }
    ${device.large} {
        margin-block-start: 50px;
    }
`;

export const StyledSubtitle = styled.h3`
    color: #fff;
    margin-block-end: 15px;
    font-size: 3.5rem;
    text-transform: initial;
    ${device.medium} {
        margin-block-end: 20px;
    }
`;

export const StyledSubtitle2 = styled.h3`
    color: var(--bs-site-color);
    margin-block-end: 15px;
    font-size: 3.5rem;
    text-transform: initial;
    ${device.medium} {
        margin-block-end: 20px;
    }
`;

export const StyledTitle = styled.h1`
    font-size: clamp(40px, 6.3vw, 120px);
    color: #fff;
    line-height: 1.09;
    margin-block-end: 20px;
    ${device.xlarge} {
        margin-block-end: 20px;
    }
`;

export const StyledDesc = styled.p`
    color: #fff;
    font-weight: 500;
    font-size: 1.25rem;
    opacity: 0.75;
`;
